import {mapActions, mapGetters, mapMutations} from "vuex";
import dressCardTable from "./dress-card-table/index.vue";
import moment from "moment/moment";
//sections
export default {
  name: "dress-card",
  components: {
    dressCardTable,
  },
  props: {
    dress: {
      type: Object,
      default: ()=> {},
    }
  },
  data() {
    return {
      tableArray: []
    }
  },
  created() {
    this.tableArray.push(JSON.parse(JSON.stringify({...this.dress, uuid: Date.now(), payload: {
        size: null,
        train: null,
        count: null,
        date: moment().format("YYYY-MM-DD"),
        comment: '',
      }})))
  },
  watch: {

  },
  computed: {
    ...mapGetters({

    }),
  },
  mounted() {

  },
  methods: {
    ...mapActions({
      addToCart: 'collections/ADD_DRESS_TO_CART',
      fetchProfile: 'profile/FETCH_USER_DATA'
    }),
    handleAddDress() {
      const newDress = JSON.parse(JSON.stringify({...this.dress, uuid: Date.now(), payload: {
          size: null,
          train: null,
          count: null,
          date: moment().format("YYYY-MM-DD"),
          comment: '',
        }}));
      this.tableArray.push(newDress);
    },
    handleDeleteDress(index) {
      this.tableArray = this.tableArray.filter(el => el.uuid !== index)
    },
    handleAddToCart(){
      const payload = this.tableArray.map(el => {
        return {
          retail_dress_size_id: el.payload.size.value,
          train_option: el.payload.train.value ? 'with_train' : 'without_train',
          quantity: el.payload.count.value,
          wedding_date: el.payload.date,
          comment: el.payload.comment,
        }
      })
      this.addToCart({data: payload}).then(()=>{
        this.fetchProfile()
        this.$toasted.success('Added to cart!')
      })
    },
  }
}