import {mapActions, mapGetters, mapMutations} from "vuex";

import mainButton from '@/components/main-button/index.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
//sections
export default {
  name: "trunk-list",
  components:{
    mainButton
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchTrunk().then(()=>{
          if(this.$route.query.scroll){
            window.scrollTo({
              top: 550,
              behavior: "smooth"
            });
            this.$router.replace({ query: {} });
          }
        })
      }
    }
  },
  data(){
    return{

    }
  },
  created() {
    this.fetchTrunk().then(()=>{
      if(this.$route.query.scroll){
        window.scrollTo({
          top: 550,
          behavior: "smooth"
        });
        this.$router.replace({ query: {} });
      }
    })
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      contentList: 'pages/trunk',
    }),
  },

  methods:{
    ...mapActions({
      fetchTrunk: 'pages/GET_TRUNK'
    }),

  }
}