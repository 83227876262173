import {$http} from '@/utils/https';
// import {globalLoader, fixBody} from '@/helpers/variables/index';
import {
  SET_TOKEN,
  REMOVE_TOKEN,
  CHANGE_POPUP_RESTORE_PASSWORD,
  CHANGE_POPUP_NEW_PASSWORD,
  SET_LOADING_REGISTRATION,
  CHANGE_POPUP_LDAP,
  SING_IN_WITH_LDAP_LOADING,
  CHECK_EMAIL_LOADING,
  CHANGE_LOADING_SING_IN,
  CHANGE_LOADING
} from '../mutation-types';
import {
  GET_TOKEN,
  REGISTRATION,
  LOGOUT,
  RESET_PASSWORD,
  SET_NEW_PASSWORD,
  SING_IN_WITH_GOOGLE,
  SING_IN_WITH_FACEBOOK,
} from '../action-types';

const state = {
  isAuthenticated: localStorage.getItem('user_token') !== null,
  showRestorePassword: false,
  showSetNewPassword: false,
  loadingRegistration: false,
  loadingSingIn: false,
  popupLdapWarning: false,
  checkEmailLoading: false,
  isLoading: false
};

const getters = {
  isAuthenticated: state => state.isAuthenticated,
  checkEmailLoading: state => state.checkEmailLoading,
  showRestorePassword: state => state.showRestorePassword,
  showSetNewPassword: state => state.showSetNewPassword,
  singIn: state => state.singIn,
  loadingRegistration: state => state.loadingRegistration,
  loadingSingIn: state => state.loadingSingIn,
  isLoading: state => state.isLoading,
  popupLdapWarning: state => state.popupLdapWarning
};

const actions = {
  [GET_TOKEN]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_SING_IN, true);
    try {
      const result = await $http.post('v1/auth/login', payload);
      commit(SET_TOKEN, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SING_IN, false)
    }
  },
  [SING_IN_WITH_GOOGLE]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_SING_IN, true);
    try {
      const result = await $http.post(`v1/auth/social_login?provider=${payload.provider}&token=${payload.token}`);
      console.log('GOGRES',result.data);
      commit(SET_TOKEN, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SING_IN, false);
    }
  },
  [SING_IN_WITH_FACEBOOK]: async ({commit}, payload) => {
    commit(CHANGE_LOADING_SING_IN, true);
    try {
      const result = await $http.post(`v1/auth/social_login?provider=${payload.provider}&token=${payload.token}`);
      console.log('fbLOG', result);
      commit(SET_TOKEN, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING_SING_IN, false);
    }
  },
  [LOGOUT]: async ({commit}) => {
    try {

      return await $http.post('v1/auth/logout');
    } catch (e) {
      throw e;
    } finally {
      commit(REMOVE_TOKEN);
    }
  },
  [REGISTRATION]: async ({commit}, payload) => {
    try {
      commit(SET_LOADING_REGISTRATION, true);

      const result = await $http.post('v1/auth/register', payload);
      console.log(result);
      // commit(SET_TOKEN, result.data.data.accessToken);
      return result;
    } catch (e) {
      throw e;
    } finally {
      commit(SET_LOADING_REGISTRATION, false);
    }
  },
  [RESET_PASSWORD]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true)
    try {

      return await $http.post('v1/auth/password/reset', payload);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
  [SET_NEW_PASSWORD]: async ({commit}, payload) => {
    commit(CHANGE_LOADING, true)
    try {
      const result = await $http.post('v1/auth/password/restore', payload);
      commit(SET_TOKEN, result.data);
    } catch (e) {
      throw e;
    } finally {
      commit(CHANGE_LOADING, false)
    }
  },
};

const mutations = {
  [SET_TOKEN](state, token) {
    state.isAuthenticated = true;
    localStorage.setItem('user_token', token.accessToken);
    if (token.rememberToken) {
      localStorage.setItem('remember_token', token.rememberToken);
    }
  },
  [REMOVE_TOKEN](state) {
    localStorage.removeItem('user_token');
    localStorage.removeItem('remember_token');
    state.isAuthenticated = false;
    setTimeout(() => {
      location.reload()
    }, 200)

  },
  [CHANGE_POPUP_RESTORE_PASSWORD](state, status) {
    state.showRestorePassword = status;
    // fixBody(status)
  },
  [CHECK_EMAIL_LOADING](state, status) {
    state.checkEmailLoading = status;
  },
  [SET_LOADING_REGISTRATION](state, status) {
    state.loadingRegistration = status;
  },
  [CHANGE_LOADING](state, status) {
    state.isLoading = status;
  },
  [CHANGE_LOADING_SING_IN](state, status) {
    state.loadingSingIn = status;
  },
  [CHANGE_POPUP_NEW_PASSWORD](state, status) {
    state.showSetNewPassword = status;
    // fixBody(status)
  },
  [CHANGE_POPUP_LDAP](state, status) {
    state.showPopupLdap = status;
    // fixBody(status)

  },
  [SING_IN_WITH_LDAP_LOADING](state, status) {
    state.singInLdapLoading = status;
  },
};
export default {
  state,
  getters,
  actions,
  mutations,
};
