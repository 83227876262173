import {mapActions, mapGetters, mapMutations} from "vuex";

import mainButton from '@/components/main-button/index.vue';
import AOS from 'aos';
import 'aos/dist/aos.css';
//sections
export default {
  name: "exhibitions",
  components:{
    mainButton
  },
  watch: {
    '$route'(newVal){
      if(newVal){
        this.fetchContent('meeting-place').then(()=>{
          if(this.$route.query.scroll){
            window.scrollTo({
              top: 550,
              behavior: "smooth"
            });
            this.$router.replace({ query: {} });
          }
        })
      }
    }
  },
  data(){
    return{

    }
  },
  created() {
    this.resetSkip()
    this.fetchContent('meeting-place').then(()=>{
      if(this.$route.query.scroll){
        window.scrollTo({
          top: 550,
          behavior: "smooth"
        });
        this.$router.replace({ query: {} });
      }
    })
  },
  mounted() {
    AOS.init({
      disable: function () {
        const maxWidth = 700;
        return window.innerWidth <= maxWidth;
      }
    });
  },
  computed:{
    ...mapGetters({
      contentList: 'pages/content',
      limit: 'pages/limit'
    }),
  },

  methods:{
    ...mapActions({
      fetchContent: 'pages/GET_CONTENT'
    }),
    ...mapMutations({
      changeSkip: 'pages/CHANGE_PAGE_SKIP',
      resetSkip: 'pages/RESET_SKIP'
    }),
    loadMore(){
      this.changeSkip()
      this.fetchContent('meeting-place')
    },
  }
}