//auth
export const GET_TOKEN = 'GET_TOKEN';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';
export const SING_IN_WITH_GOOGLE = 'SING_IN_WITH_GOOGLE';
export const SING_IN_WITH_FACEBOOK = 'SING_IN_WITH_FACEBOOK';
//setting
export const GET_LANGUAGE_LIST = 'GET_LANGUAGE_LIST';
export const GET_TRANSLATIONS = 'GET_TRANSLATIONS';
export const GET_CATEGORIES_MENU = 'GET_CATEGORIES_MENU';
export const GET_CATEGORY_FILTERS = 'GET_CATEGORY_FILTERS';
export const GET_VARIABLES = 'GET_VARIABLES';
//header
export const GET_HEADER_SEARCH_TOOLTIP = 'GET_HEADER_SEARCH_TOOLTIP';
//search
export const GET_SEARCH_LIST = 'GET_SEARCH_LIST';
//contacts
export const SEND_FEEDBACK = 'SEND_FEEDBACK';
export const GET_CONTACTS = 'GET_CONTACTS';
export const GET_MAGAZINES = 'GET_MAGAZINES';
//faq
export const GET_FAQ_LIST = 'GET_FAQ_LIST';
export const SEND_ASK = 'SEND_ASK';
//home
export const GET_ADVANTAGE_LIST = 'GET_ADVANTAGE_LIST';
export const GET_MAIN_SLIDER = 'GET_MAIN_SLIDER';
//config
export const GET_CONFIG = 'GET_CONFIG';
//products
export const GET_SLIDER_PRODUCTS = 'GET_SLIDER_PRODUCTS';
//requisites
export const CHECK_AVAILABILITY_PROPS = 'CHECK_AVAILABILITY_PROPS';
//basket
export const CHECK_PROMO_CODE = 'CHECK_PROMO_CODE';
export const RESERVED_REQUISITES = 'RESERVED_REQUISITES';
//props selection
export const GET_PROPS_SELECTION = 'GET_PROPS_SELECTION';
export const CHECK_COUNT_PRODUCTS = 'CHECK_COUNT_PRODUCTS';
export const GET_ALL_FILTERS = 'GET_ALL_FILTERS';
//category
export const GET_LIST = 'GET_LIST';
export const GET_CUSTOM_BREADCRUMBS = 'GET_CUSTOM_BREADCRUMBS';
export const GET_LIST_CATEGORY = 'GET_LIST_CATEGORY';
//card
export const GET_PRODUCT = 'GET_PRODUCT';

//personal
export const FETCH_ORDERS = 'FETCH_ORDERS';
//setting
export const FETCH_USER_DATA = 'FETCH_USER_DATA';
//profile
export const UPDATE_USER_DATA = 'UPDATE_USER_DATA';
export const UPDATE_USER_PASSWORD = 'UPDATE_USER_PASSWORD';
export const LINK_WITH_GOOGLE = 'LINK_WITH_GOOGLE';
export const LINK_WITH_FACEBOOK = 'LINK_WITH_FACEBOOK';
export const UNLINK_WITH_GOOGLE = 'UNLINK_WITH_GOOGLE';
export const UNLINK_WITH_FACEBOOK = 'UNLINK_WITH_FACEBOOK';
//orders
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const CANCEL_ORDER = 'CANCEL_ORDER';
//history
export const GET_HISTORY_LIST = 'GET_HISTORY_LIST';
//favorites
export const GET_FAVORITES_LIST = 'GET_FAVORITES_LIST';
export const ADD_PRODUCT_TO_FAVOURITES = 'ADD_PRODUCT_TO_FAVOURITES';
export const REMOVE_PRODUCT_IN_FAVORITE = 'REMOVE_PRODUCT_IN_FAVORITE';

export const GET_FILTERS = 'GET_FILTERS';
export const GET_ITEM = 'GET_ITEM';
export const CREATE = 'CREATE';
export const UPDATE = 'UPDATE';
export const DELETE = 'DELETE';
export const QUICK_ORDER = 'QUICK_ORDER';
export const CREATE_ORDER = 'CREATE_ORDER';
export const CREATE_INVOICE = 'CREATE_INVOICE';
export const CREATE_TRANSACTION = 'CREATE_TRANSACTION';

export const NOVA_POSHTA_GET_TOWNS = 'NOVA_POSHTA_GET_TOWNS';
export const NOVA_POSHTA_GET_DEPARTMENS = 'NOVA_POSHTA_GET_DEPARTMENS';

//catalog
export const GET_PRODUCTS = 'GET_PRODUCTS'

//content
export const GET_CONTENT = 'GET_CONTENT'
export const GET_BANNER_CONTENT = 'GET_BANNER_CONTENT'
export const GET_PAGE_CONTENT = 'GET_PAGE_CONTENT'
export const GET_PAGE = 'GET_PAGE'
export const GET_BLOG = 'GET_BLOG'
export const GET_CONTACT = 'GET_CONTACT'

//dress
export const GET_DRESS = 'GET_DRESS'

//retailers
export const GET_LOCATION = 'GET_LOCATION'
export const GET_COUNTRIES = 'GET_COUNTRIES'
export const GET_CITIES = 'GET_CITIES'
export const GET_SHOP = 'GET_SHOP'

//delivery
export const GET_DELIVERY = 'GET_DELIVERY'
export const GET_COUNTRY = 'GET_COUNTRY'

//contact us
export const GET_CONTACT_COUNTRY = 'GET_CONTACT_COUNTRY'
export const SET_BRIDE_DATA = 'SET_BRIDE_DATA'
export const SET_SALON_DATA = 'SET_SALON_DATA'
export const GET_SOCIALS = 'GET_SOCIALS'
export const GET_TRUNK = 'GET_TRUNK'
export const GET_TRUNK_SHOW = 'GET_TRUNK_SHOW'
export const GET_COLLECTIONS = 'GET_COLLECTIONS'

//collentions

export const GET_COLLECTION_LIST = 'GET_COLLECTION_LIST'
export const GET_COLLECTION_DRESS_LIST = 'GET_COLLECTION_DRESS_LIST'
export const GET_COLLECTION_DATA = 'GET_COLLECTION_DATA'
export const ADD_DRESS_TO_CART = 'ADD_DRESS_TO_CART'
export const GET_RETAILER_CART = 'GET_RETAILER_CART'
export const DOWNLOAD_CART = 'DOWNLOAD_CART'
export const RETAIL_EMAIL = 'RETAIL_EMAIL'
export const DELETE_DRESS = 'DELETE_DRESS'
export const SUBMIT_RETAILER = 'SUBMIT_RETAILER'
export const FETCH_RETAIL_ORDERS = 'FETCH_RETAIL_ORDERS'
export const CHANGE_COMMENT = 'CHANGE_COMMENT'