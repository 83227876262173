import {mapActions, mapGetters, mapMutations} from "vuex";
import facebook from '@/components/widgets/facebook/index.vue';
import google from '@/components/widgets/google/index.vue';
import mainButton from '@/components/main-button/index.vue';
import Registration from '@/modules/auth/register/index.vue'
import {validationMixin} from 'vuelidate'
import {maxLength, minLength, required, email} from "vuelidate/lib/validators";
//sections
export default {
    name: "sign-in",
    mixins: [validationMixin],
    components: {
        mainButton,
        facebook,
        google,
        Registration
    },
    data() {
        return {
            see: false,
            payload: {
                email: '',
                password: '',
                remember: false,
            },
            selectSignIn: true,
            selectRegistration: false,
            validationErrors: {},
        }
    },
    validations: {
        payload: {
            email: {
                email,
                maxLength: maxLength(70),
                required,
            },
            password: {
                required,
                maxLength: maxLength(25),
                minLength: minLength(8),
            },
        },
    },
    created() {

    },
    computed: {
        emailError() {
            const error = []
            if (!this.$v.payload.email.$dirty) {
                return error
            }
            if (!this.$v.payload.email.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.email.email) {
                error.push(this.$t('errorEmail'))
            }
            if (!this.$v.payload.email.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 70))
            }
            if (this.validationErrors.email) {
                this.validationErrors.email.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
        passwordError() {
            const error = []
            if (!this.$v.payload.password.$dirty) {
                return error
            }
            if (!this.$v.payload.password.required) {
                error.push(this.$t('errorRequired'))
            }
            if (!this.$v.payload.password.maxLength) {
                error.push(this.$t('errorMaxLength').replace(':count', 25))
            }
            if (!this.$v.payload.password.minLength) {
                error.push(this.$t('errorMinLength').replace(':count', 8))
            }
            if (this.validationErrors.password) {
                this.validationErrors.password.forEach((row) => {
                    error.push(row)
                })
                this.validationErrors = {}
            }
            return error
        },
    },

    methods: {
        ...mapMutations({}),
        ...mapActions({
            login: `auth/GET_TOKEN`,
        }),
        selectForm() {
            switch (this.selectSignIn) {
                case true:
                    this.selectRegistration = true
                    this.selectSignIn = false
                    break;
                case false:
                    this.selectRegistration = false
                    this.selectSignIn = true
                    break;
            }
        },
        submitForm() {
            this.$v.$touch();
            if (!this.$v.payload.$invalid) {
                this.login(this.payload).then((result) => {
                    this.$router.push({name: 'profile'}).catch(() => {
                    })
                }).catch(error => {
                    if (error.status === 422) {
                        this.$toasted.error(error.data.message);
                        const errors = error.data.errors;
                        for (const i in errors) {
                            errors[i].forEach(e => {
                                this.$toasted.error(e);
                            })
                        }
                    }
                    if (error.status === 418) {
                        this.$toasted.error(error.data.message);
                        const errors = error.data.errors;
                        for (const i in errors) {
                            errors[i].forEach(e => {
                                this.$toasted.error(e);
                            })
                        }
                    }
                })
            }
        }
    }
}
